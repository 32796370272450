import * as React from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    BooleanInput,
    DateInput,
} from 'react-admin';
import { Divider, Box } from '@mui/material';

export const ContactInputs = () => (
    <Box flex="1" mt={-1}>
        <Box display="flex" width={430}>
            <TextInput source="first_name" fullWidth />
            <Spacer />
            <TextInput source="last_name" fullWidth />
        </Box>
        <Divider />
        <Box mt={2} width={430}>
            <TextInput source="email" fullWidth />
        </Box>
        <Box display="flex" width={430}>
            <TextInput source="phone_number" fullWidth />
        </Box>
        <Divider />
        <Box display="flex" width={430}>
            <TextInput source="address" fullWidth />
            <Spacer />
            <TextInput source="city" fullWidth />
        </Box>
        <Box display="flex" width={430}>
            <TextInput source="state" fullWidth />
            <Spacer />
            <TextInput source="zip" fullWidth />
        </Box>
        <Divider />
        <Box mt={2} width={430}>
            <DateInput source="customer_since"  fullWidth />
            
        </Box>
    </Box>
);

const Spacer = () => <Box width={20} component="span" />;
