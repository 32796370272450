import * as React from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    BooleanInput,
    DateInput,
    SelectInput,
    ReferenceField,
    NumberInput,
    ImageField,
    useRecordContext,
    TextField,
    FunctionField,
} from 'react-admin';
import { Divider, Box, Grid, Typography, Avatar } from '@mui/material';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useEffect, useState } from 'react';
import { deepPurple } from '@mui/material/colors';

const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
const dateParseRegex = /(\d{4})-(\d{2})-(\d{2})/;

const convertDateToString = (value: string | Date) => {
    // value is a `Date` object
    if (!(value instanceof Date) || isNaN(value.getDate())) return '';
    const pad = '00';
    const yyyy = value.getFullYear().toString();
    const MM = (value.getMonth() + 1).toString();
    const dd = value.getDate().toString();
    return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};

const dateFormatter = (value: string | Date) => {
    // null, undefined and empty string values should not go through dateFormatter
    // otherwise, it returns undefined and will make the input an uncontrolled one.
    if (value == null || value === '') return '';
    if (value instanceof Date) return convertDateToString(value);
    // Valid dates should not be converted
    if (dateFormatRegex.test(value)) return value;

    return convertDateToString(new Date(value));
};

const dateParser = (value: string) => {
    //value is a string of "YYYY-MM-DD" format
    const match = dateParseRegex.exec(value);
    if (match === null || match.length === 0) return;
	const d = new Date(parseInt(match[1]), parseInt(match[2], 10) - 1, parseInt(match[3]));
    if (isNaN(d.getDate())) return;
    return d;
};

export const RepairCreateInputs = () => {
    
    return (
    <Grid  container columnGap={4} >
   
        <Grid ml={3} item xs={5}>

         <Box>
         <ReferenceInput source="contact_id" reference="contacts" />
            <TextInput label="Ticket Number" source="repair_ticket_number" fullWidth />
        </Box>   
        <Box>
            <TextInput source="description" fullWidth />
        </Box>             
        <Box display="flex" >
            <Box mr={1} display="flex" >
                <NumberInput  source="amount"  />
            </Box>

            <SelectInput fullWidth source="status" choices={[
                { id: 'inprogress', name: 'In Progress' },
                { id: 'completed', name: 'Completed' },
                { id: 'notified', name: 'Notified' },
                { id: 'pickedup', name: 'Pickedup' },
            ]} />


        </Box>
        

        
        <Box display="flex" >
            <DateInput source="received_at" format={dateFormatter} parse={dateParser} defaultValue="" fullWidth />           
        </Box>
        <Box display="flex" >
            <DateInput source="completed_at" format={dateFormatter} parse={dateParser} defaultValue="" fullWidth />
        </Box>
        

        <Box display="flex" >
            <DateInput source="notified_at" format={dateFormatter} parse={dateParser} defaultValue="" fullWidth />
        </Box>
 
        <Box display="flex" >
        
            <DateInput  source="pickedup_at" format={dateFormatter} parse={dateParser} defaultValue="" fullWidth />
        </Box>
        </Grid>
        <Grid item xs={3} >    
            <Box>                
                
            </Box> 
        </Grid>
    </Grid>
    )
};

const Spacer = () => <Box width={20} component="span" />;
