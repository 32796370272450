import { Box, Typography } from "@mui/material";
import { SimpleForm } from "react-admin";
import { ImageInput, ImageField, useNotify } from 'react-admin';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";



const UploadServiceTicketPickedupContent =  () => {
  const notify = useNotify();
  const uploadPhotos = async (data: any) => { 
    
    try {
      // Assuming uploadPhoto is a function that uploads a photo
      const promises = data.service_ticket.map(async (photo: any) => { 
          const blob = photo.rawFile;
          console.log('photo', photo);
          const storage = getStorage();
          const timestamp = Date.now();
          const imageRef = ref(storage, 'inbox/service_tickets/' + photo.title + timestamp);
          uploadBytes(imageRef, blob).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
              console.log("File available at", url);
            })
          });
        });
      await Promise.all(promises);
      notify('All Service tickets uploaded',{ type: 'success' });
      console.log('All photos uploaded');
    } catch (error) {
      console.error('Error uploading photos:', error);
    }
  };

  return (
   <Box>
      <SimpleForm  mt={5} onSubmit={uploadPhotos} >
      <Typography variant="h6" color="textPrimary" component="p">
          Pick Up Service Ticket
          </Typography>
          <br/>
          <ImageInput sx={{border: 1}}  source="service_ticket" accept="image/*" multiple label="Related pictures" placeholder={<p>Drop service ticket image here</p>}>
              <ImageField source="src" title="title" />
          </ImageInput>
        
      </SimpleForm>
      </Box>
  );
}

export const UploadServiceTicketPickup = () => (  
  <Box>
    <UploadServiceTicketPickedupContent />
  </Box>
);