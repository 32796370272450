/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import { RepairList } from './RepairList';
import { RepairEdit } from './RepairEdit';
import { RepairCreate } from './RepairCreate';


export default {
  list: RepairList,
  create: RepairCreate,
  edit: RepairEdit,
};
