/* eslint-disable import/no-anonymous-default-export */
import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    RecordContextProvider,
    TextInput,
    SearchInput,
    Filter,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    DateField,
    Datagrid,
    DatagridHeaderProps,
    FieldProps,
} from 'react-admin';
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    TableHead,
    TableRow,
    TableCell,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';

import { TagsList } from './TagsList';
import { ContactListFilter } from './ContactListFilter';
import { Contact } from '../types';

const ContactListContent = () => {
    const {
        data: contacts,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext<Contact>();
    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    const now = Date.now();



    return (
        <>
            <BulkActionsToolbar>
                <BulkDeleteButton />
            </BulkActionsToolbar>
            <List title="Contacts">
            <Datagrid  rowClick="show" >
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="phone_number" />
            <TextField source="email" />
            <DateField source="customer_since" />
            
            
        </Datagrid>
            </List>
        </>
    );
};

const ContactListActions = () => (
    <TopToolbar>
         <SortButton fields={['last_name', 'first_name']} />
         <ExportButton />
         <CreateButton
             variant="contained"
             label="New Contact"
             sx={{ marginLeft: 2 }}
         />
     </TopToolbar>
    
);


const contactFilters = [
    <SearchInput  source="first_name" alwaysOn />,
   
];


export const ContactList = () => {
    const { identity } = useGetIdentity();
    return identity ? (
        <RaList
            actions={<ContactListActions />}
            filters={contactFilters}
            perPage={50}
            pagination={<Pagination rowsPerPageOptions={[50, 100]} />}
        >
            <ContactListContent />
        </RaList>
    ) : null;
};
