import * as firebaseConfig from "./FIREBASE_CONFIG.js";
import {
    Admin,
    CustomRoutes,
    ListGuesser,
    Resource,
    combineDataProviders,
    houseLightTheme,
    defaultTheme,
    nanoLightTheme,
    localStorageStore,
} from 'react-admin';

import {
    FirebaseDataProvider,
    FirebaseAuthProvider
  } from "react-admin-firebase";

import { initializeApp } from "firebase/app"
import * as Messaging from "firebase/messaging";
import { Layout, Login} from './layoutmain';
import contacts from './contacts';
import repairs from './repairs';
import { Dashboard } from './dashboard/Dashboard';
import { Route } from 'react-router';
import { UploadNewServiceTicket } from './repairs/UploadNewRepairTicket.js';
import { UploadServiceTicketComplete } from './repairs/UploadRepairTicketComplete.js';
import { UploadServiceTicketPickup } from './repairs/UploadRepairTicketPickup.js';
import { DiamondCRMSettingsEditor } from './settings/DiamondCRMSettingsEditor';
const options = { }

const appConfig = firebaseConfig.default;

const firebaseApp = initializeApp(appConfig);
const authProvider = FirebaseAuthProvider(appConfig, options);

const messaging = Messaging.getMessaging(firebaseApp);
Messaging.onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
});

const dataProvider = FirebaseDataProvider(firebaseConfig, {
    logging: true,
    // rootRef: 'rootrefcollection/QQG2McwjR2Bohi9OwQzP',
    app: firebaseApp,
    // watch: ['posts'];
    // dontwatch: ['comments'];
    persistence: 'local',
    // disableMeta: true
    dontAddIdFieldToDoc: true,
   
    firestoreCostsLogger: {
      enabled: true,
    },
  });


const App = () => (
    <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        layout={Layout}
        loginPage={Login}
        dashboard={Dashboard}
        theme={{
            ...houseLightTheme
        }}
    >
        
        <Resource name="contacts" {...contacts}  />
        <Resource name="repairs" {...repairs} />
        <CustomRoutes>
            <Route path="/service/new" element={<UploadNewServiceTicket />} />
            <Route path="/service/complete" element={<UploadServiceTicketComplete />} />
            <Route path="/service/pickup" element={<UploadServiceTicketPickup />} />
            <Route path="/settings/default" element={<DiamondCRMSettingsEditor />}  />
        </CustomRoutes>
    </Admin>
    
);


export default App;
export { messaging, authProvider, dataProvider }
