import * as React from 'react';
import { useState } from 'react';

import VisitorIcon from '@mui/icons-material/People';
import CameraIcon from '@mui/icons-material/Camera';
import GearIcon from '@mui/icons-material/Settings';

import {
    useTranslate,
    DashboardMenuItem,
    Menu,
    MenuProps,
    useSidebarState,
} from 'react-admin';
import { Typography } from '@mui/material';


type MenuName = 'menuContacts' ;

const DiamondMenu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuContacts: true
    });
    const translate = useTranslate();
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Menu
            dense={dense}
            hasDashboard
            sx={{marginTop: 3}} 
        >
            <DashboardMenuItem  primaryText="Feed" />
               
                <Menu.Item
                    key="contacts"
                    to="/contacts"
                    state={{ _scrollToTop: true }}
                    primaryText={"Contacts"}
                    leftIcon={<VisitorIcon />}
                    dense={dense}
                />
               <Menu.Item
                    key="repairs"
                    to="/repairs"
                    state={{ _scrollToTop: true }}
                    primaryText={"Repairs"}
                    leftIcon={<VisitorIcon />}
                    dense={dense}
                />                
                <br/>
               <Typography align="justify" sx={{marginLeft:3}} variant="subtitle1"  gutterBottom>
                    Repair Tickets
                </Typography>
                
            
                <Menu.Item to="/service/new" primaryText="New Ticket" leftIcon={<CameraIcon />} />
                <Menu.Item to="/service/complete" primaryText="Completed Ticket" leftIcon={<CameraIcon />} />
                <Menu.Item to="/service/pickup" primaryText="Pickup Ticket" leftIcon={<CameraIcon />} />
              
              <br/>
              <br/>
              <Menu.Item to="/settings/default" primaryText="System Settings" leftIcon={<GearIcon />} />
         
        </Menu>
    );
};

export default DiamondMenu;