import * as React from 'react';
import { CreateBase, Form, Toolbar } from 'react-admin';
import { Card, CardContent, Box, Avatar } from '@mui/material';

import { Repair } from '../types';
import { RepairCreateInputs } from './RepairCrateInputs';

export const RepairCreate = () => (
    <CreateBase
        redirect="show"
        transform={(data: Repair) => ({
            ...data,
        })}
    >
        <Box mt={2} display="flex">
            <Box flex="1">
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <Box mr={2}>
                                        <Avatar />
                                    </Box>
                                    <RepairCreateInputs />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
    </CreateBase>
);
