import * as React from 'react';
import {
  List as RaList,
  SimpleListLoading,
  ReferenceField,
  TextField,
  useListContext,
  FilterLiveSearch,
  Pagination,
  useGetIdentity,
  FunctionField,
  Datagrid,
  NumberField,
  SearchInput,
  ListBase,
  SelectInput,
  List,
  ReferenceInput,
  AutocompleteInput,
  DateField,
  ChipField,
  EditButton,
} from 'react-admin';
import { matchPath, useLocation } from 'react-router';
import {
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';


import { Contact, Repair } from '../types';
import { RepairEditExpand } from './RepairEdit';

const repairFilters = [
        <SearchInput placeholder="Ticket Number"  source="repair_ticket_number" alwaysOn  aria-label="ticket number" />,
        <SelectInput alwaysOn
        source="status"
        choices={[ 
            { id: 'inprogress', name: 'In Progress' },
               { id: 'completed', name: 'Completed' },
            { id: 'notified', name: 'Notified' },
            { id: 'pickedup', name: 'Picked Up' }
        ]}/>,
        <ReferenceInput  alwaysOn source="contact_id" reference="contacts">
            <AutocompleteInput  size="small"
                optionText={(choice?: Contact) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.first_name} ${choice.last_name}`
                        : ''
                }
                suggestionLimit={10}
                sx={{ minWidth: 200 }}
            />
        </ReferenceInput>,

]

const FilterSidebar = () => (
    <Card sx={{ order: -1, mr: 2, mt: 6, width: 200 }}>
        <CardContent>
            
        </CardContent>
    </Card>
);

const RepairListContent = () => {
    const {
        data: repairs,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext<Repair>();
    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    
  return (
      <>
        <ListBase>
            <Datagrid expand={<RepairEditExpand />}>
                <TextField source="repair_ticket_number" />
                <ReferenceField label="Contact" source="contact_id" reference="contacts">
                    <FunctionField render={ (record:Contact) => record && `${record.first_name} ${record.last_name}`} />
                </ReferenceField>
                <NumberField  source="amount" options={{
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2
                }} />
                <TextField source="description" />
                <DateField source="received_at" />               
                <ChipField source="status" color="primary" />
                <EditButton />
            </Datagrid>
        </ListBase>              
      </>
  );
};


export const RepairList = () => {
  const { identity } = useGetIdentity();
  return identity ? (
      <List
          filters={repairFilters}
          perPage={25}
          pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
      >
          <RepairListContent />
      </List>
  ) : null;
};
