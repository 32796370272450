import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

if (navigator.serviceWorker) {
    // Register the SW
    navigator.serviceWorker.register('/firebase-messaging-sw.js').then(function(registration){
    }).catch(console.log);
  }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function showNotification() {
    let notificationOptions = {
      body: 'Some Notification information',
      icon: '/thumb.png'
    }
    let notif = new Notification('My New Notification', notificationOptions);
  
    notif.onclick = () => {
      console.log('Notification clicked');
    }
  }