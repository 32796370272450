import * as React from 'react';
import {
    TextField,
    EmailField,
    DateField,
    ReferenceManyField,
    EditButton,
    ShowButton,
    useListContext,
    ReferenceField,
    FunctionField,
    useRecordContext,
} from 'react-admin';
import { Avatar, ListItemAvatar,Box, Typography,ListItemText, Divider, List, ListItem } from '@mui/material';
import { TagsListEdit } from './TagsListEdit';
import { ContactMessages } from './ContactMessages';
import { Contact, Sale } from '../types';

export const ContactAside = ({ link = 'edit' }: { link?: 'edit' | 'show' }) => {
    const record = useRecordContext<Contact>();
    return (
        <Box mb={3} mt={2} ml={4} width={450} minWidth={350}>
                            
            <Typography variant="h6" color="text.secondary" component="p">
                Customer Communications
            </Typography>
            <ContactMessages />
        
           
        </Box>
    );
};

const TasksIterator = () => {
    const { data, isLoading } = useListContext();
    if (isLoading || data.length === 0) return null;
    return (
        <Box>
            <Typography variant="subtitle2">Tasks</Typography>
            <Divider />

            <List>
                {data.map(task => (
                    <ListItem key={task.id} disableGutters>
                        <Box>
                            <Typography variant="body2">{task.text}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                due{' '}
                                <DateField source="due_date" record={task} />
                            </Typography>
                        </Box>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};
