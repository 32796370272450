import * as React from 'react';
import { red, deepOrange, blueGrey, green } from '@mui/material/colors';

import { useState, FormEvent, MouseEventHandler, useRef, useEffect } from 'react';
import {
    useGetMany,
    useCreate,
    useUpdate,
    Identifier,
    useRecordContext,
    Button,
    Empty,
    fetchUtils,
    useRefresh,
    useGetManyReference,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Typography,
    Divider,
    TextField,
    Box,
    Stack,
} from '@mui/material';
import { Contact, Communication, Message } from '../types';
import MessageIcon from '@mui/icons-material/Message';
import { Label } from '@mui/icons-material';

export const ContactMessages = () => {
    const record = useRecordContext<Contact>();
    const { data: communications, isLoading: isLoadingRecordCommunications, refetch } = useGetManyReference<Communication>(
        'communications',
        { target: 'contact_id', id: record.id, sort: { field: 'sent_at', order: 'ASC' }},
        { enabled: record && record.communications && record.communications.length > 0 }
    );
    const [create] = useCreate<Message>();
    const messagesEndRef = useRef<null | HTMLDivElement>(null)
    const [disabled, setDisabled] = useState(false);
    const [newMessage, setNewMessageBody] = useState('');
    const [isSending, setIsSending] = useState(false);
    const refresh = useRefresh();

    const updateMessageBody = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewMessageBody(event.target.value);
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    };

    useEffect(() => {
        scrollToBottom()
      }, [communications]);
    

    const ensurePhoneNumber = (phoneNumber: string) => {
        if (phoneNumber.startsWith('+')) {
            return phoneNumber;
        }
        return `+1${phoneNumber}`;
    };

    const sendCustomerMessage = (event: React.MouseEvent) => {
        setDisabled(true);
        setIsSending(true);
        create(
            'messages',
            { data: { contact_id: record.id, to: ensurePhoneNumber(record.phone_number), body: newMessage } },
            {
                onSuccess: comm => {
                    setNewMessageBody('');
                    setDisabled(false);      
                   
                },
            }
        );
       
       
        setTimeout(() => {
            refetch();
            setIsSending(false);
        }, 5000);
    };

    if (isLoadingRecordCommunications) return null;
    return (
        <>
         <List sx={{ width: '100%', maxHeight:350, overflow: 'auto',  maxWidth: 460, bgcolor: 'background.paper' }}>
            {communications?.map(comm => (                
                 <ListItem key={comm.id} alignItems="flex-start">                    
                        <ListItemAvatar>
                            <Avatar  sx={{ bgcolor: (comm.direction === "outgoing" ? blueGrey[500] : deepOrange[500]) }} src="/static/images/avatar/1.jpg">{comm.direction === "outgoing" ? "H" : record.first_name[0] + record.last_name[0] } </Avatar>
                        </ListItemAvatar>                
                   <ListItemText
                     primary={comm.sent_at == null ? "" : comm.sent_at.toLocaleString()}
                     secondary={
                       <React.Fragment>
                         {comm.body}
                       </React.Fragment>
                     }
                   />
                 </ListItem>                 
            ))}
            <div ref={messagesEndRef} />
            </List>
            <Divider/>
            
            
            { !isSending && <Stack direction="row" padding={1} spacing={2}>
                <TextField disabled={disabled} id="outlined-textarea" onChange={updateMessageBody} multiline minRows={3} maxRows={4} value={newMessage}  label="Your message" variant="outlined" fullWidth />
                <Button onClick={sendCustomerMessage} label='Send'><MessageIcon/></Button>
            </Stack> }

            {isSending && <Stack direction="row" padding={1} spacing={2}>
            <Typography>Sending your message...Message history will refresh once delivery is confirmed.</Typography>
                </Stack>}
        </>
    );    
};

