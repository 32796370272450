import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    Confirm,
    useRecordContext,
    useDelete,
    useUpdate,
    useCreate,
} from 'react-admin';
import { Button, CardActions } from '@mui/material';
import * as App from '../App';
import { getToken } from 'firebase/messaging';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { PushToken, ClientToken } from '../types';
import { Timestamp } from 'firebase/firestore';



const ConfirmNotificiations = () => {
    const [uid, setUid] = useState('');
    const [pushToken, setPushToken] = useState<PushToken>({} as PushToken);
    useEffect(() => {
        const getCurrentUserToken = async () => {
        const user = await App.authProvider.getAuthUser();
        console.log(`UID ` + user.uid);
        setUid(user.uid);
        try {
          console.log(App.dataProvider);
          const token = await App.dataProvider.getOne('pushTokens', { id: user.uid });
          console.log(`Token ` + JSON.stringify(token.data.tokens));
          setPushToken(token.data);
        } catch (error) {
          console.error('An error occurred while fetching token. ', error);
        }
        
      };
      getCurrentUserToken();
    }, []);
    const [open, setOpen] = useState(false);
    const [update] = useUpdate<PushToken>();
    const [create] = useCreate<PushToken>();
    
    
    const handleConfirm = () => {
      console.log('Requesting permission...');
      getToken(App.messaging, { vapidKey: 'BPDgDAoFzW9Ojrrlg_Jb1CJmTHyVRRHk9rq7nU1A5mqSTA0mpMJbR6U_Vr3t1pXFImpa8sc38d1OIj7NBBvigdM' }).then((currentToken) => {
        if (currentToken) {          
          console.log('Token available: ', currentToken);
          if (pushToken.tokens || [].length == 0) {
            try {
              create('pushTokens', {  data: { id: uid, tokens: [ { token: currentToken, timestamp: Timestamp.now() }] } });              
            }
            catch (error) {
              console.error('An error occurred while updating token. ', error);
            }
          }
          else {
            try {
              let c = {token: currentToken, timestamp: Timestamp.now()};
              let p = pushToken;
              console.log(JSON.stringify(p));
              p.tokens.push(c);
              update('pushTokens', { id: uid, data: { p } });
            }
            catch (error) {
              console.error('An error occurred while updating token. ', error);
            }
          }
          
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });      
    };
    
    if ((pushToken.tokens || [].length > 0) ) { 
      return <></>;
    }


    return (
      <Card sx={{
        background: `#c5dedd`,
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '1em',
        marginBottom: '1em',
        marginTop: '2em',
        [`& .MuiCardActions-root`]: {
            p: 2,
            mt: -2,
            mb: -1,
            flexDirection: 'column',
            '& a': {
                mb: 1,
                color: 'rgba(0, 0, 0, 0.87)',
                backgroundColor: 'white',
                marginLeft: '0 !important',
            },
        },
    }}>
        <CardHeader title="Notifications">Notifications</CardHeader>
          <CardContent>                     
            <Typography variant="body2" color="text.secondary">We need your permission to send most up to date notifications.</Typography>                      
          </CardContent>
          <CardActions>            
          <Button variant="contained"
                fullWidth size="medium" color="primary"                 
                  onClick={handleConfirm}
                  startIcon={<NotificationsActiveIcon />}
                 >
                Enable Notifications
                  </Button>
        </CardActions>
        </Card>
    );
};

export default ConfirmNotificiations