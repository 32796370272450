import * as React from 'react';
import { BooleanInput, Edit, EditBase, Form, SimpleForm, TextInput, Toolbar, useEditContext, useRecordContext, useResourceContext } from 'react-admin';
import { Card, CardContent, Box, Avatar, Typography } from '@mui/material';

import { DiamondCRMSettings } from '../types';
import { Label } from '@mui/icons-material';


export const DiamondCRMSettingsEditor = () => {

    return (
        <Edit  resource="settings"
        id="default" redirect={false} title="Diamond CRM Settings">
           <SimpleForm sx={{ maxWidth: 800 }}>
           <Typography variant="h6" gutterBottom>
                Messages
            </Typography>
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>                
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <Typography variant="h6" gutterBottom>Phone Number:</Typography>
                    <Typography variant="body1" gutterBottom>516-550-4140</Typography>
                </Box>
            </Box>            
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <BooleanInput label="Send New Ticket Created SMS " source="send_notification_upon_ticket_creation" isRequired fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <TextInput multiline label="SMS Content"
                 source="ticket_created_message" isRequired fullWidth />
                </Box>
            </Box>            
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <BooleanInput label="Send Ticket Ready SMS " source="send_notification_upon_ticket_completion" isRequired fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <TextInput multiline label="SMS Content"
                 source="ticket_ready_message" isRequired fullWidth />
                </Box>
            </Box>                        
                    
        </SimpleForm>
        </Edit>
    );
};