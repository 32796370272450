import * as React from 'react';
import {
    ShowBase,
    useShowContext,
    NumberField,
    Show,
    TabbedShowLayout,
    Datagrid,
    ReferenceManyField,
    TextField,
    DateField,
    ChipField,
    ReferenceManyCount,
    Edit,
    EditButton,
} from 'react-admin';
import { deepOrange, blueGrey } from '@mui/material/colors';
import { Avatar, Box, Card, CardContent, Stack, Typography } from '@mui/material';

import { ContactAside } from './ContactAside';
import { Contact } from '../types';
import { RepairEditExpand } from '../repairs/RepairEdit';

export const ContactShow = () => (
    <ShowBase>
        <ContactShowContent />
    </ShowBase>
);

const ContactShowContent = () => {
    const { record, isLoading } = useShowContext<Contact>();
    if (isLoading || !record) return null;
    return (
        <Box mt={2} display="flex">
        <Box flex="1">
            <Show>
                <TabbedShowLayout>
                    <TabbedShowLayout.Tab label="summary">                        
                        <Box display="flex">
                            <Avatar sx={{ bgcolor: deepOrange[500]}}  />
                            <Box ml={2} flex="1">
                                <Typography variant="h5">
                                    {record.first_name} {record.last_name}
                                </Typography>
                                <br/>
                                <Typography variant="body2">
                                    Phone Number: {record.phone_number} 
                                </Typography>
                                <Typography variant="body2">
                                
                                    Email: {(record.email === "") ? 'Not Entered' : record.email}
                                </Typography>                                
                            </Box>
                            <Box>
                            <Typography variant="body2">
                                <b>Customer Since</b>
                            </Typography>
                            <DateField options={{
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                }} source="customer_since"  />
                            </Box>                           
                        </Box>
                        
                        <Box>
                            <Typography variant="h6">
                                Repairs                                    
                            </Typography>
                            <ReferenceManyField label="Repairs" reference="repairs" target="contact_id">
                                <Datagrid>                                           
                                    <TextField source="description" />
                                    <DateField source="received_at" />
                                    <ChipField source="status" color="primary" />
                                    <NumberField  source="amount" options={{
                                        style: 'currency',
                                        currency: 'USD',
                                        minimumFractionDigits: 2
                                    }} />
                                </Datagrid>
                            </ReferenceManyField>
                        </Box>                            
                        <Box>

                        </Box>
                    </TabbedShowLayout.Tab>
                    <TabbedShowLayout.Tab label="repairs">
                        <Box>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6">
                                        Repairs
                                    </Typography>
                                    <ReferenceManyField label="Repairs" reference="repairs" target="contact_id">
                                        <Datagrid expand={<RepairEditExpand />}>
                                            <TextField source="repair_ticket_number" />
                                            <TextField source="description" />
                                            <DateField source="received_at" />
                                            <ChipField source="status" color="primary" />
                                            <NumberField  source="amount"  options={{
                                                style: 'currency',
                                                currency: 'USD',
                                                minimumFractionDigits: 2
                                            }} />
                                            <EditButton />
                                        </Datagrid>
                                        </ReferenceManyField>
                                </CardContent>
                            </Card>
                        </Box>
                    </TabbedShowLayout.Tab>
                </TabbedShowLayout>
            </Show>       
        </Box>
        <Box>
            <ContactAside />
        </Box>
    </Box>
        
    );
};
