import * as React from 'react';
import { Edit, EditBase, Form, ReferenceField, SelectInput, SimpleForm, Toolbar, useEditContext, useRecordContext, useResourceContext } from 'react-admin';
import { Card, CardContent, Box, Avatar, CardHeader, Typography } from '@mui/material';
import HandymanIcon from '@mui/icons-material/Handyman';
import { RepairInputs } from './RepairInputs';
import { Repair } from '../types';
import { Label } from '@mui/icons-material';


export const RepairEditExpand = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  return (
      <Edit
          resource={resource}
          id={record.id}
          /* disable the app title change when shown */
          title="Repair Details"
      >
          <SimpleForm>
          <SelectInput source="status" choices={[
            { id: 'inprogress', name: 'In Progress' },
            { id: 'completed', name: 'Completed' },
            { id: 'notified', name: 'Notified' },
            { id: 'pickedup', name: 'Pickedup' },
        ]} />
          </SimpleForm>
      </Edit>
  );
};

export const RepairEdit = () => (
  
    <EditBase redirect="list">
      
        <RepairEditContent />
    </EditBase>
);

const RepairEditContent = () => {
  const { isLoading, record } = useEditContext<Repair>();
    if (isLoading || !record) return null;
    return (
        <Box mt={2} display="flex">
            <Box flex="1">
                <Form>
                    <Card>
                        <CardHeader title="Repair Details" />
                        <CardContent>

                                <Box sx={{fontSize: 24}} display="flex">
                                    
                                    <RepairInputs />
                                </Box>

                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>          
        </Box>
    );
};
