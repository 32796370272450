import * as React from 'react';
import { Grid } from '@mui/material';


import { FeedUpdatesList} from './Feed';
import ConfirmNotificiations from './ConfirmNotifications';


// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.



export const Dashboard = () => {
    
    return (
    <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={10}>
            <ConfirmNotificiations />
        </Grid>
        <Grid item xs={12} md={10}>
        <FeedUpdatesList />
       </Grid>
        
    </Grid>
)};
