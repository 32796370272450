import { List, SimpleList, SimpleListLoading, useGetList, useListContext, useUpdate } from 'react-admin';
import { FeedUpdate } from '../types';
import { Box, Button, Card, CardActions, CardContent, CardHeader, IconButton, Stack, Typography } from '@mui/material';
import {CheckCircle, AccountCircleRounded } from '@mui/icons-material';
import HandymanIcon from '@mui/icons-material/Handyman';
import MessageIcon from '@mui/icons-material/Message';
import InfoIcon from '@mui/icons-material/Info';
import ClearIcon from '@mui/icons-material/Clear';
import { Link } from 'react-router-dom';
import { useRefresh } from 'react-admin';

export const FeedUpdatesList = () => {
  const { data, total, isLoading, error } = useGetList(
    'feed',
    { 
        filter: { isRead: false },
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'created_at', order: 'DESC' }
    }
);

  const [update] = useUpdate<FeedUpdate>();
  const refresh = useRefresh();
  const dismissFeedUpdate = (id: string) => { 
    update('feed', { id: id, data: { isRead: true } });
    refresh();
  }

if (isLoading) {
    return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
}
const now = Date.now();

const getRouteForUpdate = (feedUpdateType: String, target_id: String) : String => {
  switch (feedUpdateType) {
        case 'contact_created':
            return `/contacts/${target_id}/show`;
        case 'repair_created':
        case 'repair_completed':
        case 'repair_pickedup':
          return `/contacts/${target_id}/show/1`;
        case 'message_received':
          return `/contacts/${target_id}/show`;
        default:
            throw new Error(`Unknown feed update type: ${feedUpdateType}`);
    }
}

const pickHeaderIconBasedonFeedUpdateType = (feedUpdateType: String) => {
  if (feedUpdateType === 'contact_created')
    return <AccountCircleRounded color='info' sx={{fontSize: '44px'}} />;
  if (feedUpdateType === 'repair_created' || feedUpdateType === 'repair_completed' || feedUpdateType === 'repair_pickedup')
    return <HandymanIcon color='success' sx={{fontSize: '44px'}} />;
  if (feedUpdateType === 'message_received')
    return <MessageIcon color='action' sx={{fontSize: '44px'}} />;
}

return(
  <>
    <Stack spacing={2}>
    {(data || []).map(feedUpdate => (      
      <Card  variant="elevation" key={feedUpdate.id}>   
        <CardHeader avatar={pickHeaderIconBasedonFeedUpdateType(feedUpdate.type)} 
        title= {feedUpdate.title}
        subheader={feedUpdate.created_at.toLocaleDateString()}        
      />       
          <CardContent >       
            <Typography variant="h6" color="text.primary">
            {feedUpdate.body}
            </Typography>
          </CardContent>                
        <CardActions     sx={{
      alignSelf: "stretch",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-start",      
    }}>
        <IconButton size="small" aria-label="Details" color="primary" component={Link} to={`${getRouteForUpdate(feedUpdate.type, feedUpdate.contact_id)}`}>
            <InfoIcon/> Details
        </IconButton>
          <IconButton size="small" aria-label="Dismiss" onClick={() => dismissFeedUpdate(feedUpdate.id)} color="secondary">
            <ClearIcon/> Dismiss
          </IconButton>
      </CardActions>
      </Card>
     
    ))}
    </Stack>
    
  </>
);
};