import { Box, Typography } from "@mui/material";
import { SimpleForm, useNotify, useRedirect } from "react-admin";
import { ImageInput, ImageField } from 'react-admin';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";



const UploadNewServiceTicketContent =  () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const uploadPhotos = async (data: any) => { 
    try {
      // Assuming uploadPhoto is a function that uploads a photo
      const promises = data.service_ticket.map(async (photo: any) => { 
          const blob = photo.rawFile;
          console.log('photo', photo);
          const storage = getStorage();
          const timestamp = Date.now();
          const imageRef = ref(storage, 'inbox/service_tickets/' + photo.title + timestamp);
          uploadBytes(imageRef, blob).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
              console.log("File available at", url);
            })
          });
        });
      await Promise.all(promises);
      console.log('All photos uploaded');
      setTimeout(() => {
        redirect("/#/");
      }, 5000);
      notify('All Service tickets uploaded',{ type: 'success' })
    } catch (error) {
      console.error('Error uploading photos:', error);
    }
  };

  return (
   
      <SimpleForm mt={5}  onSubmit={uploadPhotos} >
        <Typography variant="h6" color="textPrimary" component="p">
          Upload New Service Ticket
          </Typography>
          <br/>
        <ImageInput sx={{border: 1}}  source="service_ticket" accept="image/*" multiple label="Related pictures" placeholder={<p>Drop service ticket image here</p>}>
            <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    
  );
}

export const UploadNewServiceTicket = () => (  
  <Box>
    <UploadNewServiceTicketContent />
  </Box>
);